"use client";
import HiddenWhenAuth from "@/components/fusion/hidden-when-auth";
import React from "react";

const Footer = () => {
  return (
    <HiddenWhenAuth>
      <footer className="padding-container max-container mx-auto py-5 text-center">
        Copyrights ©2024 Universitas Amikom Purwokerto. All rights reserved.
      </footer>
    </HiddenWhenAuth>
  );
};

export default Footer;
