import Image from "next/image"
import Link from "next/link"
import React from "react"
import { Card } from "../ui/card"
import { TabsContent } from "../ui/tabs"

const TabItems = ({ kurikulumData }) => {
  return (
    <>
      {kurikulumData.map((item, index) => (
        <TabsContent key={index} value={item.fakultas}>
          <div className="grid lg:grid-cols-3 gap-10 my-20">
            {item.matakuliah.map((matakuliah, index) => (
              <Card
                key={index}
                className="rounded-xl bg-purple-700 overflow-hidden p-0 flex flex-col justify-between">
                <div className="p-10">
                  <p className="text-3xl font-bold text-white w-9/12 leading-snug">
                    {matakuliah.nama_matakuliah}
                  </p>
                  <p className="text-white">{matakuliah.pertemuan} Pertemuan</p>
                </div>

                <Link
                  href={`/${matakuliah.kode}`}
                  className="w-full bg-purple-500 py-5 px-10 text-2xl text-white font-bold flex gap-3 items-center justify-between">
                  Lihat matkul
                  <Image
                    src={"/icons/arrowjoin.png"}
                    width={125}
                    height={1}
                    alt="arrow"
                  />
                </Link>
              </Card>
            ))}
          </div>
        </TabsContent>
      ))}
    </>
  )
}

export default TabItems
